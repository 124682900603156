.image-accordion {
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1,1fr);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  
    .image-accordion__item {
      position: relative;
      width: 150px;
      height: 150px;
      overflow: hidden;
      cursor: pointer;
      border-radius: 10px;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        transition: transform 0.3s ease-in-out;
      }
  
      .image-accordion__item-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
  
        .image-accordion__item-content__title {
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
        }
  
        .image-accordion__item-content__description {
          font-size: 14px;
          text-align: center;
        }
      }
  
      &:hover,
      &.active {
        img {
          transform: scale(1.2);
        }
  
        .image-accordion__item-content {
          transform: translateY(0);
        }
        
      }
    }
  }
  