.privacity-container{
    margin-top: 10%;
    height: auto;
    text-align: start;
    margin-bottom: 10%;
    

    h1{
        text-align: center;
    }
    p, ul, li, h3{
        line-height: 2.5;
    }
    span{
        text-decoration: underline;
    }

}