.navigation-container {
    height: 75;
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem;
    //background-color: #F8CBA6;
    background-color: #ECF9FF;
    background-color: black;
    z-index: 100;
    justify-content: space-between;
    margin-bottom: 25px;
    border-radius: 1px;
    box-shadow: 0 4px 4px rgba(255, 255, 255, 0.4);
    //color: azure;

    .hide-item {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: white;
        padding-top: 25px;
        padding-right: 10%;
    }


    .logo-container {
        height: 100px;
        width: 70px;
    }

    .logo {
        height: 80px;
        width: 134.05px;
        padding: 10px;
    }

    .links-container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: white;
        background-color: black;
        //background-color: #ECF9FF;
        padding-top: 25px;
        padding-right: 10%;
    }

    .navlink {
        padding: 10px 15px;
        cursor: pointer;
        color: white;
        text-decoration: none;

        &:hover {
            color: black;
            background-color: #c0dbfb;
            border-radius: 10px;
        }
    }

    .mobile-menu-icon {
        padding-top: 10%;
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 4rem;
        cursor: pointer;
        display: none;
        outline: none;
    }

    .mobile-menu-close {
        display: none;
    }

    @media only screen and (max-width: 768px) {
        padding: 0;

        .navigation-container {
            width: 50%;
        }

        .hide-item {
            display: none;
            transform: translateY(calc(-100% - var(--top)));
        }

        .mobile-menu-icon {
            display: flex;
        }

        .links-container {
            --top: 1rem;
            transition: 0.3s transform;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            //background-color: #c0dbfb;
            background-color: #070707;
            padding: 2rem;
            width: 90%;
            max-width: 300px;
            border-radius: 12px;
            position: absolute;
            right: 1rem;
            top: var(--top);

            .mobile-menu-close {
                display: block;
                justify-content: end;
                width: 3rem;
                margin: 0 0 0 auto;
                cursor: pointer;

                * {
                    pointer-events: none;
                }

                .navlink {
                    display: block;
                    margin-bottom: 1rem;
                }
            }
        }

    }

}