.pstyle {
    width: 80%;
    //max-width: 1000px;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.3em;
    font-family: 'Open Sans';
    opacity: 85%;
    /*background: linear-gradient(to left, 
        rgb(170, 172, 172) 0%,
        rgb(211, 211, 211) 100%
    )*/;
    background: linear-gradient(to right, 
        /*#e7e7e7 0%,*/
        white 0%,
        #ECF9FF 50%,
        #F4F6FF 100%
    );
    border-radius: 10px;
    padding: 1rem;
    
    span{
        //background-color: #FFE7CC;
        font-style: italic;
        font-weight: bold;
        //border-radius: 10px;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.3rem;
    }
}