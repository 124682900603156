.about-container {
    padding-top: 10%;
    width: 100%;
    height: auto;
    //background-image: url('../../img/back_web_2.png');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 75%;

    .about-background {
        width: auto;
        justify-content: center;

        img {
            width: auto;
        }

    }

    .about-topic {
        border: 1px;
        text-align: center;
        font-size: 2.5rem;
        line-height: 1.5;
        //color: #f96e6e;
        color: #000;
        margin-bottom: 24px;
        text-decoration: underline;
        text-underline-offset: 1rem;
        text-shadow: 2px 3px 5px #fff;

    }

    .about-valors {
        //width: 100%;
        display: grid;
        flex-wrap: wrap;
        justify-content: center;
        flex: auto;
        //background-color: black;
        //width: 100%;
        //display: grid;

        /*.PStyle {
            display: flex;
            flex: wrap;
            justify-content: space-between;
        }*/


    }

    .about-policy {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        //font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }

    .about{
        justify-content: center;
        width: 40%;
        text-align: justify;
    }

    @media only screen and (max-width: 768px) {
        height: auto;
    }

}


.test-container {
    //height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .left-side {
        
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1,1fr);
        //flex-direction: column;
        justify-content: space-between;
        width: 50%;
        //background-color: #f96e6e;

        .background-image {
            width: 100%;
            height: auto;
            background-size: cover;

            div {
                flex: 1;
                display: flex;
                height: 100%;
                float: left;
                padding: 5px;
                border: 1px solid black;
                background-color: #f96e6e;

                &:hover {
                    transform: skew(10deg);
                    transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
                }
            }
        }
    }



    .right-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        //background-color: gray;
    }


    .about-topic {
        border: 1px;
        text-align: center;
        font-size: 2.5rem;
        line-height: 1.5;
        //color: #f96e6e;
        color: #000;
        margin-bottom: 24px;
        text-shadow: 2px 3px 5px #fff;
        font-weight: 900;

    }
}

@media only screen and (max-width: 768px){
    .about-policy {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        text-align: center;
        //font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }

    .about-container{
        text-align: center;
    }

    .test-container{
        text-align: center;
    }

}