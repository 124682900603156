.contact-container {
    //background-color: black;
    //margin: 10rem 20rem;
    //width: 100%;
    //height: calc(100vh);
    //height: auto;
    padding: 5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-image: url('../../img/back_web_4.png');
    background-size: cover;
    //background-attachment: fixed;
    background-repeat: no-repeat;



    .left-container {
        //background-color: #007bff;
        width: 100%;
        padding: 20px;
        //padding-right: 1.5rem;
        margin: 0 auto;
        flex: 1;
        order: 1;

        h1,h2,h3 {
            text-align: center;
        }

        h1{
            font-size: 2.3rem;
            font-family: Verdana;
        }
        h3{
            font-size: 1.1rem;
            font-family: Arial;
            font-weight: 900;
        }

        .contact-info-container {
            padding: 2rem;
            border: 1px solid black;
            border-radius: 20px;
            background-color: whitesmoke;
            color: black;
            opacity: 75%;

            .phones-container{
                display: flex;
                justify-content: center;
            }
        }
    }

    .test_slider{
        width: 100%;
        justify-content: center;
        text-align: center;
        display: flex;
        background-color: black;
        padding: 10%;
    }

    .right-container {
        width: 100%;
        padding: 20px;
        margin: 0 auto;
        //background-color: #fff;
        flex: 1;
        order: 2;

        .form-container {
            background-color: whitesmoke;
            border: 1px solid black;
            border-radius: 20px;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            max-width: 400px;
            margin: 0 auto;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
            }


            .input-form-container {
                padding: 8px;
                font-size: 16px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-bottom: 16px;

                &:focus {
                    outline: none;
                    border-color: #007bff;
                    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
                }
            }

            .input-textarea-container {
                height: 100px;
                resize: vertical;
                padding: 8px;
                font-size: 16px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-bottom: 16px;

                &:focus {
                    outline: none;
                    border-color: #007bff;
                    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
                }
            }

            .contact-buttom-form {
                padding: 8px 16px;
                font-size: 16px;
                border: none;
                border-radius: 4px;
                background-color: #007bff;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: #0069d9;
                }
            }

        }

    }

    @media (max-width: 767px) {
        .left-container {
          width: 100%;
          order: 1;
        }
    
        .right-container {
          width: 100%;
          order: 2;
        }
      }
}