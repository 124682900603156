.accordion-menu {
    .accordion-menu__item {
      //background-color: #f7dbdb;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin-bottom: 10px;
      transition: all 0.3s ease cubic-bezier(0,1,0,1);
  
      &:last-child {
        margin-bottom: 0;
      }
  
      &.active {
        .accordion-menu__item-header {
          background-color: #636675;
          color: white;
        }
  
        .accordion-menu__item-content {
          display: block;
        }
      }
  
      &:hover {
        cursor: pointer;
      }
    }
  
    .accordion-menu__item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      &:hover{
        background-color: #b9bcc7;
      }
  
      .accordion-menu__item-title {
        margin: 0;
      }
  
      .accordion-menu__item-icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease cubic-bezier(1,0,1,0);
  
        &__bar {
          width: 15px;
          height: 2px;
          background-color: #333;
          margin: 2px 0;
          transition: all 0.3s ease cubic-bezier(1,0,1,0);
        }
  
        &__bar:first-child {
          transform: rotate(45deg);
        }
  
        &__bar:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  
    .accordion-menu__item-content {
      padding: 10px;
      display: none;
    }
  }
  