.home-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: auto;

    .home-background {
        padding-top: 25%;
        width: 100%;
        height: calc(100vh);
        //background-image: url("https://lh3.googleusercontent.com/blogger_img_proxy/AHs97-kqZQRsX1xmD8Hddf_aGL9dhhfP4EHG4ZtuqwBWDFTbwQ6A0gz8OXUWVrrO4g4r7ROKrHRKiirWrsJN7-Myn6HozaUKZpRnOikxdBiTPlyLS1jp_LT5miDbr_p1XQZur4Z4zSgmarG3Qmjir797cdZzRXjzgyD7p2TKUBHAUGXUYNeRAJaniYU=w919-h516-p-k-no-nu");
        background-image: url('../../img/back_web_principal.png');
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        text-align: center;
    }

    .home-info-container {
        display: contents;
        font-size: 100px;
        //color: rgb(211, 211, 211);
        //font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        color: white;
        text-shadow: 2px 5px 3px #000;
    }

    .home-title {
        margin-top: 30vh; // adjust as needed to center title vertically
    }


    @media only screen and (max-width:768px) {
        .home-background {
            padding-top: 60%;
        }

        .home-info-container {
            width: 100%;
            height: 100%;
            margin-top: 50%;
            font-size: 48px;
            text-shadow: 2px 3px 3px #000;
        }
    }
}