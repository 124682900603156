.footer-container {
    //position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
    padding-top: 5%;
    /*background-color: #D1D1D1;*/
    background-color: black;
    color: white;
    display: flex;

    justify-content: space-evenly;
    align-items: center;

    .copyright-container {
        display: block;
        justify-content: center;
        //background-color: black;
    }

    .sections-container {
        color: whitesmoke;

        .links-container {
            flex: 1;
            display: block;
            justify-content: center;
            color: white;

            li {
                display: flex;
                padding-top: 5%;
                color: white;
            }
        }
    }

    .socials-container {
        //background-color: black;
        display: fixed;
        justify-content: center;

        .social-links-container {
            flex: 1;
            display: flex;
            padding: 1rem;

            li {
                display: flex;
                padding: 0.5rem;
                justify-content: space-between;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        //position: absolute;
        bottom: 0;
        //margin-top: 25%;
        img{
            width: 100px;
            height: 59.68px;
        }
    }

}