body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Prata';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;

  font-family: 'Open Sans';
}

.app-title {
  margin-top: 75px;
  margin-bottom: 50px;
  font-size: 76px;
  color: rgb(134, 43, 40);
  font-family: 'Bigelow Rules';
}