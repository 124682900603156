.services-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 5%;
    background-image: url('../../img/back_web_2.png');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;

    .services-title {
        text-align: center;
        //color: #f96e6e;
        color: #000;
        font-family: 'Arial Narrow Bold', sans-serif;
        font-size: 2.5rem;
        line-height: 1;
        //text-shadow: 2px 3px 3px #000;
    }

    h4 {
        color: #6e83f9;
    }

    ul {
        font-family: "Open Sans";

        p {
            padding: 1rem;
        }
    }


    .services-test-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        left: 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;




        .services-test-container-item {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: auto;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 640px;
            height: 427px;
            //background-image: url('../../img/logo_banner.jpg');
            background-size: cover;
            background-position: center;
            border: 1px solid #636675;
            padding: 1rem;
            margin-bottom: 1rem;
            margin-left: 5%;


            &:hover {
                transform: scale(1.1);
                transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);

                .content {
                    border-radius: 5px;
                    background: linear-gradient(to right,
                            /*#e7e7e7 0%,*/
                            white 0%,
                            #ECF9FF 50%,
                            #F4F6FF 100%);
                    opacity: 100;
                    color: #000;
                }
            }

            .content-title {
                text-align: center;
                //color: #f96e6e;
                color: #ffff;
                text-shadow: 2px 3px 3px #000;
                padding-top: 35%;
                text-decoration: underline;
                text-underline-offset: 0.3rem;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1.4rem;
            }

            .content {
                opacity: 0;
            }

            

            .services-test-right {
                display: flex;
                width: 90%;
                float: right;
                right: 0;

            }
        }
    }
}

@media only screen and (max-width:768px) {
    .services-container {
        text-align: center;
        .services-test-container {
            display: flex;
            flex-wrap: wrap;
            width: 90%;
            left: 0;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto;
            gap: 1rem;




            .services-test-container-item {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                .content-title {
                    text-align: center;
                    //color: #f96e6e;
                    color: #ffff;
                    text-shadow: 2px 3px 3px #000;
                    padding-top: 15%;
                    text-decoration: underline;
                    text-underline-offset: 0.3rem;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1.4rem;
                }

                .content{
                    margin-top: 3%;
                }
            }

        }
    }
}